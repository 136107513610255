import Roles from "@/utils/roles"

export default [
    {
        path: "/segmentos",
        name: "segmentos",
        component: () => import("@/views/area-mention/segmentos/index.vue"),
        meta: {
          title: 'Segmentos',
          authRequired: true,
          roles: [Roles.Segmentos]
        },
    },
];