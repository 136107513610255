import TimeOnSiteTracker from "@/assets/timeonsitetracker"
import apiClient from "@/services/api-base";
import store from "@/state/store";
import { ref } from "vue";
const tos = ref(null)

export const deleteTrackUserTime = () => {
    tos.value = null
}

export const enviarTos = (evt) => {
    try {
        if (evt.timeOnPage > 5) {
            let urlToSend = ''
            let oldJson = sessionStorage.getItem('timeOnPage')
            if (oldJson && oldJson !== '') {
                let oldTos = JSON.parse(oldJson)
                urlToSend = oldTos.URL
            }

            return apiClient.post('account/tos', {
                timeOnPage: evt.timeOnPage,
                page: urlToSend
            })
        }
    }
    catch(err) {
        console.log('enviarTos ', err)
    }
    return null
}

export const tryInitTrackUserTime = (email) => {
    try {
      if (!tos.value) {
        var config = {
          trackBy: 'seconds',
          trackHistoryChange: true,
          callback: (evt) => {
            //não logar menos de 5 segundos na página
            if (evt.timeOnPage > 5 && store.getters['account/loggedIn']) {
                enviarTos(evt)?.catch((err) => {
                    console.log('erro add tos', err)
                })
                sessionStorage.setItem('timeOnPage', JSON.stringify(evt))
            }
          }
        };
        tos.value = new TimeOnSiteTracker(config)
        tos.value.startSession(email)
        tos.value.setCustomData({ closing: true });
        window.removeEventListener("beforeunload", beforeunload)
        window.addEventListener("beforeunload", beforeunload);
      }
      
      tos.value.startSession(email)
    }
    catch(err) {
      console.log('initTrackUserTime ', err)
    }
}

const beforeunload = async () => {
    if (tos.value) {
        var time = tos.value.getTimeOnPage()
        await enviarTos(time)?.catch((err) => {
            console.log('erro add tos', err)
        })
        return; 
    }
}