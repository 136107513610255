import Roles from "@/utils/roles"

export default [
  {
      path: "/templates-notificacoes",
      name: "templates-notificacoes",
      component: () => import("@/views/area-mention/templates-notificacoes/index.vue"),
      meta: {
        title: 'Templates de Notificações',
        authRequired: true,
        roles: [Roles.TemplateEmails]
      },
  },
  {
    path: "/templates-notificacoes/criar-design",
    name: "templates-notificacoes-criar-template",
    component: () => import("@/views/area-mention/templates-notificacoes/form-template.vue"),
    meta: {
      title: 'Templates de Notificações',
      authRequired: true,
      roles: [Roles.TemplateEmailsGerenciar]
    },
  },
  {
    path: "/templates-notificacoes/design/:id",
    name: "templates-notificacoes-editar-template",
    component: () => import("@/views/area-mention/templates-notificacoes/form-template.vue"),
    meta: {
      title: 'Templates de Notificações',
      authRequired: true,
      roles: [Roles.TemplateEmails]
    },
  },
  {
    path: "/templates-notificacoes/modelo/:id",
    name: "templates-notificacoes-editar-modelo",
    component: () => import("@/views/area-mention/templates-notificacoes/form-modelo.vue"),
    meta: {
      title: 'Templates de Notificações',
      authRequired: true,
      roles: [Roles.TemplateEmails]
    },
  },
];