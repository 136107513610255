import axios from "axios";
import store from "@/state/store";
import router from "@/router/index";
import { notifyError, openModalError } from "@/state/helpers";

const checkErrorNetowrk = (/*error*/) => {
  //TODO enviar log remoto
  //console.log(error)
  /*if (error.code === 'ERR_NETWORK') {
        openModalError('ERR_NETWORK')
        return true
    }
    return error.code === 'ERR_NETWORK'*/
  return false;
};

class ApiRest {
  constructor() {}

  getClient(
    timeout = 10000,
    addInterceptor401 = true,
    responseType = "",
    contentType = "application/json"
  ) {
    var client = axios.create({
      baseURL: process.env.VUE_APP_APIURL,
      timeout: timeout,
      responseType: responseType,
      headers: {
        "Content-Type": contentType,
        "Accept-Language": "pt-BR",
      },
    });

    if (addInterceptor401) {
      client.interceptors.response.use(
        (response) => response,
        (error) => {
          var hasNetworkError = checkErrorNetowrk(error);
          if (
            !hasNetworkError &&
            error.response &&
            error.response.status === 401
          ) {
            if (router.currentRoute.value.meta.authRequired) {
              openModalError("NOT_AUTHORIZED");
            }
          } else if (
            !hasNetworkError &&
            error.response &&
            error.response.status === 404
          ) {
            openModalError("NOT_FOUND");
          } else {
            if (!hasNetworkError && !error.response && error.message) {
              if (
                    error.message.toLowerCase() == "network error" 
                    || error.code?.toLowerCase() == "err_network" 
                    || (error?.response?.status ?? 0) >= 500
                ) {
                const log = {
                  url: error?.config?.url,
                  method: error?.config?.method,
                  requestHeaders: error?.config?.headers,
                  requestData: error?.config?.data,
                  responseHeaders: error?.response?.headers,
                  responseData: error?.response?.data,
                  timestamp: new Date().toISOString(),
                  errorMessage: error?.message,
                };
                armazenarLog(log);
              } else {
                notifyError(error.message);
              }
            }
          }
          return Promise.reject(error);
        }
      );
    } else {
      client.interceptors.response.use(
        (response) => response,
        (error) => {
          checkErrorNetowrk(error);
          return Promise.reject(error);
        }
      );
    }
    return client;
  }

  async post(
    path,
    params,
    reqAuth = true,
    headers = [],
    timeout = 180000,
    responseType = "",
    contentType = "application/json"
  ) {
    if (reqAuth) {
      return this.getClient(timeout, true, responseType, contentType).post(
        path,
        params ?? {},
        this.getToken(headers)
      );
    } else {
      return this.getClient(timeout, false, responseType, contentType).post(
        path,
        params ?? {}
      );
    }
  }

  async put(path, params, reqAuth = true, timeout = 180000, responseType = "") {
    if (reqAuth) {
      return this.getClient(timeout, true, responseType).put(
        path,
        params ?? {},
        this.getToken()
      );
    } else {
      return this.getClient(timeout, false, responseType).put(
        path,
        params ?? {}
      );
    }
  }

  async delete(
    path,
    params,
    reqAuth = true,
    timeout = 180000,
    responseType = ""
  ) {
    var queryString = params
      ? Object.keys(params)
          .map(function (key) {
            return key + "=" + params[key];
          })
          .join("&")
      : "";
    if (reqAuth) {
      return this.getClient(timeout, true, responseType).delete(
        `${path}?${queryString}`,
        this.getToken()
      );
    } else {
      return this.getClient(timeout, false, responseType).delete(
        `${path}?${queryString}`
      );
    }
  }

  getParamValue(vl) {
    return vl === null || vl === undefined ? "" : vl;
  }
  getArrayToQueryString(array, fieldName, parentKey) {
    if (array.length > 0) {
      let result = "";
      array.forEach((element, index) => {
        if (typeof element === "string" || element instanceof String) {
          result += `${parentKey}${fieldName}[${index}]=${element}&`;
        } else {
          const keys = Object.keys(element);
          if (keys.length === 0) {
            result += `${parentKey}${fieldName}[${index}]=${this.getParamValue(
              element
            )}&`;
          } else {
            result +=
              keys
                .map((key) => {
                  if (Array.isArray(element[key])) {
                    return this.getArrayToQueryString(
                      element[key],
                      key,
                      `${parentKey}${fieldName}[${index}].`
                    );
                  } else {
                    if (isNaN(key)) {
                      return `${parentKey}${fieldName}[${index}].${key}=${this.getParamValue(
                        element[key]
                      )}`;
                    } else {
                      return `${parentKey}${fieldName}[${index}]=${this.getParamValue(
                        element[key]
                      )}`;
                    }
                  }
                })
                .join("&") + "&";
          }
        }
      });
      return result;
    }
    return fieldName + "=";
  }

  getQueryStringParam(object, key) {
    if (Array.isArray(object[key])) {
      return this.getArrayToQueryString(object[key], key, "");
    } else {
      return key + "=" + this.getParamValue(object[key]);
    }
  }
  async get(path, params, reqAuth = true, timeout = 180000, responseType = "") {
    var queryString = params
      ? Object.keys(params)
          .map((key) => this.getQueryStringParam(params, key))
          .join("&")
      : "";
    if (reqAuth) {
      return this.getClient(timeout, true, responseType).get(
        `${path}?${queryString}`,
        this.getToken()
      );
    } else {
      return this.getClient(timeout, false, responseType).get(
        `${path}?${queryString}`
      );
    }
  }

  getToken(headers = []) {
    var r = {
      headers: {
        Authorization: "Bearer " + store.getters["account/token"],
      },
    };
    for (var idx = 0; idx < headers.length; idx++) {
      r.headers[headers[idx].name] = headers[idx].value;
    }
    return r;
  }
}

import ApiRestFake from "./api-fake";
import { armazenarLog } from "./logs-service";
const apiClient =
  process.env.VUE_APP_APIENV === "fake" ? new ApiRestFake() : new ApiRest();

export default apiClient;
