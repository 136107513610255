<template>
    <BRow :class="`gy-2 mt-${props.mt}`">
        <BCol md="12" class="mb-2">
            <h6 class="fw-light fs-lg">{{ props.title }} </h6>
            <hr class="my-2" />
        </BCol>
    </BRow>
</template>

<script setup>
    import {defineProps} from "vue"
    const props = defineProps({
        title: { type: String, required: true },
        mt: { type: Number, required: false, default: 3 }
    })
</script>