export const state = {
};

export const getters = { }



export const mutations = {
    MESSAGE_RECEIVED() {
    },
}

export const actions = {
    messageReceived({ commit }, body) {
        commit('MESSAGE_RECEIVED', body);
    }
};
