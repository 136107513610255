import apiClient from '@/services/api-base';
import { useWebsocket } from '@/services/websocket';
import { clearFileDB } from "@/utils/indexedDb"
const ws = useWebsocket()

const clear = () => {
    localStorage.clear(); 
    sessionStorage.clear();
    clearFileDB();
}

const logoutApi = (loading) => {
    apiClient.post('account/logout', {}).then(() => {
        console.log('Log off realizado com sucesso')
    })
    .finally(() => {
        clear()
        if (loading) {
            loading.value = false
        }
    })
}

const logout = (loading) => {
    ws.disconnect()
    if (loading) {
        logoutApi(loading)
    } else {
        clear()
    }
}
export default logout