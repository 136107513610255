import WaitDataLoad from "@/components/loadings/wait-data-load.vue"
import SubheaderSeparator from "@/components/subheader-separator.vue"
import TextInput from '@/components/inputs/input.vue'
import TextArea from '@/components/inputs/textarea.vue'
import DateInput from '@/components/inputs/date.vue'
import SelectInput from '@/components/inputs/select.vue'
import SelectAjax from '@/components/inputs/select-ajax.vue'
import CheckboxGroupInput from '@/components/inputs/checkbox-group.vue'
import RadioGroupInput from '@/components/inputs/radio-group.vue'
import SearchBoxInput from '@/components/inputs/searchbox.vue'

const setup = (app) => {
    app.component("WaitDataLoad", WaitDataLoad);
    app.component("Separator", SubheaderSeparator);

    app.component("TextInput", TextInput);
    app.component("TextAreaInput", TextArea);
    app.component("SelectInput", SelectInput);
    app.component("SelectAjaxInput", SelectAjax);
    app.component("CheckboxGroupInput", CheckboxGroupInput);
    app.component("RadioGroupInput", RadioGroupInput);
    app.component("DateInput", DateInput);
    app.component("SearchBoxInput", SearchBoxInput);
} 

export default setup