import routesClientes from "./clientes";
import routesPedidos from "./pedidos";
import routesRelatorios from "./relatorios";
import routesSegmentos from "./segmentos";
import routesSugestoesConteudo from "./sugestoes-conteudo";
import routesTemplatesNotificacoes from "./templates-notificacoes";
import routesTermosUso from "./termos-uso";
import routesUsuarios from "./usuarios";

export default [
  ...routesClientes, ...routesPedidos, ...routesRelatorios, 
  ...routesSegmentos, ...routesSugestoesConteudo, ...routesTemplatesNotificacoes, 
  ...routesTermosUso, ...routesUsuarios 
];
