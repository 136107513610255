import routesAccount from './routes-account'
import routesNovaConta from './routes-nova-conta'
import routesAreaCliente from './area-cliente/index'
import routesAreaMention from './area-mention/index'
import store from "@/state/store"
import Roles from '@/utils/roles'

const routes = [
  // Dashboards
  {
    path: "/",
    name: "home",
    meta: { 
      title: "Home", 
      authRequired: true,
      roles: [Roles.AllLoggedUsers] 
    },
    component: () => store.getters['account/isMention'] ? 
      import("@/views/area-mention/home/index.vue") : 
      import("@/views/area-cliente/home/index.vue"),
  },
  {
    path: "/notificacoes",
    name: "area-notificacoes",
    meta: { 
      title: "Notificações", 
      authRequired: true,
      roles: [Roles.AllLoggedUsers] 
    },
    component: () => import("@/views/shared/area-notificacao.vue") ,
  },
  {
    path: "/meu-perfil",
    name: "meu-perfil",
    meta: { 
      title: "Editar meu perfil", 
      authRequired: true,
      roles: [Roles.AllLoggedUsers]
    },
    component: () => import("@/views/shared/editar-perfil/index.vue") ,
  },
  {
    path: "/perguntas-frequentes",
    name: "perguntas-frequentes",
    meta: { title: "Perguntas frequentes", authRequired: true },
    component: () => import("@/views/shared/perguntas-frequentes/index.vue") ,
  },
  {
    path: "/template-plano-comunicacao",
    name: "template-plano-comunicacao",
    meta: { title: "Plano de comunicação", authRequired: true },
    component: () => import("@/views/shared/templates-html/template-plano-comunicacao.vue") ,
  },
];

export default [
  ...routes, ...routesAreaCliente, 
  ...routesAccount, ...routesNovaConta, ...routesAreaMention
];
