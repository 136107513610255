import Roles from "@/utils/roles"

export default [
  {
    path: "/minha-conta",
    name: "minha-conta",
    component: () => import("@/views/area-cliente/minha-conta/index.vue"),
    meta: {
      title: 'Minha Conta',
      authRequired: true,
      roles: [Roles.LerMinhaEmpresa]
    },
  },
  {
    path: "/minha-conta/preencher/dados-basicos",
    name: "informacoes-adicionais-dados-basicos",
    component: () => import("@/views/area-cliente/complementar-informacoes/dados-basicos"),
    meta: {
      title: 'Dados básicos',
      authRequired: true,
      roles: [Roles.FillClientData]
    },
  },
  {
    path: "/minha-conta/preencher/dados-complementares",
    name: "informacoes-adicionais-dados-complementares",
    component: () => import("@/views/area-cliente/complementar-informacoes/dados-complementares"),
    meta: {
      title: 'Dados complementares',
      authRequired: true,
      roles: [Roles.FillClientData]
    },
  },
  {
    path: "/minha-conta/preencher/relacao-imprensa",
    name: "informacoes-adicionais-relacao-imprensa",
    component: () => import("@/views/area-cliente/complementar-informacoes/relacao-imprensa"),
    meta: {
      title: 'Relação com a imprensa',
      authRequired: true,
      roles: [Roles.FillClientData]
    },
  },
  {
    path: "/minha-conta/preencher/material-utilizado",
    name: "informacoes-adicionais-material-utilizado",
    component: () => import("@/views/area-cliente/complementar-informacoes/material-utilizado"),
    meta: {
      title: 'Material utilizado',
      authRequired: true,
      roles: [Roles.FillClientData]
    },
  },
  {
    path: "/minha-conta/preencher/kickoff",
    name: "informacoes-adicionais-kickoff",
    component: () => import("@/views/area-cliente/complementar-informacoes/kickoff"),
    meta: {
      title: 'Agendar Kick off',
      authRequired: true,
      roles: [Roles.FillClientData]
    },
  }
];