<template>
    <BCol :md="props.cols" class="mb-3">
        <label :class="`form-label${props.required ? ' form-label-required' : ''}`">{{ props.label }}</label>
        <vSelect v-model="selected" :options="options" 
            label="text"
            :state="v == null ? null : v.$errors.length == 0"
            :placeholder="props.placeholder"
            @search="filterAjax"
            :disabled="$attrs.disabled"
            :multiple="props.multiple"
        >
            <template v-slot:no-options="{ search, searching }">
                <template v-if="searching"> 
                    Sem resultados para <em>{{ search }}</em>.
                </template>
                <em v-else style="opacity: 0.5">Começe a digitar para pesquisar.</em>
            </template>
        </vSelect>
        <BFormInvalidFeedback v-for="(error, index) of errors()" :key="`error_${index}_${error.$message}`">{{ error.$message }}</BFormInvalidFeedback>
    </BCol>
</template>

<style>
@import "vue-select/dist/vue-select.css";
</style>

<script setup>
    import vSelect from "vue-select";

    import { defineProps, defineEmits, ref, watch, onMounted } from 'vue';
    import apiClient from "@/services/api-base"

    const filtered = ref(false)
    const options = ref([])
    const selected = ref()
    const loadedInitialValue = ref(false)

    const filterAjax = (query, loading) => {
        if (!query || query.length == 0) {
            return;
        }
        try {
            filtered.value = true
            loading(true)
            apiClient.get(`${props.url}`, props.functionParams(query)).then(({data}) => {
                options.value = data.rows
                loading(false)
            });
        } catch (error) {
            console.log(`error on filterAjax ${props.url}`,error)
        }
    }

    const emit = defineEmits(['update:modelValue', 'change'])
    const props = defineProps({
        modelValue: {  },
        required: { type: Boolean, default: false },
        label: { type: String, required: true },
        placeholder: { type: String, default: 'Digite para iniciar pesquisa' },
        cols: { required: true },        
        v: { type: Object, required: false, default: null },
        mode: { type: String, required: false, default:"single" },
        url: { type: String, required: true},
        urlLoad: { type: String},
        multiple:  { type: Boolean, default: false },
        functionParams: { type: Function, default: (query) => { return { filtro: query } } }
    })


    const errors = () => {
        if (props.v == null) {
            return []
        }
        return props.v.$errors
    }

    watch(selected,
        (v) => {
            if (Array.isArray(v)) {
                let newValue = v.map(m => m.value)
                //added
                let added = newValue.filter(x => !(props.modelValue ?? []).includes(x)); 
                //removed
                let removed = (props.modelValue ?? []).filter(x => !newValue.includes(x)); 

                emit('update:modelValue', newValue)
                emit('change', newValue, added, removed)
            } else {
                emit('update:modelValue', v ? v.value : null)
                emit('change', v ? v.value : null)
            }
        }
    )

    const loadInitialValue = (newValue) => {
        if (!props.urlLoad) { 
            return;
        }
        if (Array.isArray(newValue) && newValue.length > 0) {
            loadedInitialValue.value = true
            apiClient.get(`${props.urlLoad}`, { ids: newValue }).then(({ data }) => {
                options.value = data.rows;
                selected.value = data.rows;
            });
        } else if (newValue && newValue > 0) {
            loadedInitialValue.value = true
            apiClient.get(`${props.urlLoad}`, { id: newValue }).then(({ data }) => {
                options.value = data.rows;
                selected.value = data.rows[0];
            });
        }
    }

    watch(() => props.modelValue, (newValue, oldValue) => { 
        if (loadedInitialValue.value === false && props.urlLoad && newValue && newValue !== oldValue && filtered.value === false) {
            loadInitialValue(newValue);
        }
        if (newValue !== oldValue && (!newValue || newValue.length == 0)) {
            selected.value = null
        }
    })

    onMounted(() => {
        if (props.modelValue) {
            loadInitialValue(props.modelValue)
        }
    })


</script>