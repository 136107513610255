import Roles from "@/utils/roles"

export default [
  {
    path: "/meu-plano-comunicacao",
    name: "meu-plano-comunicacao",
    component: () => import("@/views/area-cliente/plano-comunicacao/index.vue"),
    meta: {
      title: 'Plano de Comunicação',
      authRequired: true,
      roles: [Roles.LerMeuPlanoComunicacao]
    },
  },
];