import Roles from "@/utils/roles";

const routes = [
  // auth
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/account/login"),
    meta: {
      title: 'Entra no sistema',
      authRequired: false
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("@/views/account/forgot-password"),
    meta: {
      title: 'Recuperar sua senha',
      authRequired: false
    },
  },
  {
    path: "/link-change-password/:code",
    name: "link-change-password",
    component: () => import("@/views/account/change-password-link"),
    meta: {
      title: 'Alterar a senha do usuário',
      authRequired: false
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: { 
      title: "Logout", 
      authRequired: false,
    },
    component: () => import("@/views/account/logout"),
  },
  {
    path: "/termos-e-condicoes",
    name: "terms-and-conditions",
    meta: { 
      title: "Termos e Condições", 
      authRequired: true,
      roles: [Roles.AcceptTerms]
    },
    component: () => import("@/views/account/terms-and-conditions"),
  },
];
export default routes;
