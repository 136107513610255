import Roles from "@/utils/roles"

export default [
  {
    path: "/novo-pedido",
    name: "novo-pedido",
    component: () => import("@/views/area-cliente/novo-pedido/select-produto.vue"),
    meta: {
      title: 'Novo Pedido',
      authRequired: true,
      roles: [Roles.CriarMeusPedidos]
    },
  },
  {
    path: "/novo-pedido/criar/:produtoId",
    name: "novo-pedido-create",
    component: () => import("@/views/area-cliente/novo-pedido/form.vue"),
    meta: {
      title: 'Novo Pedido',
      authRequired: true,
      roles: [Roles.CriarMeusPedidos]
    },
  },

  {
    path: "/meus-pedidos",
    name: "meus-pedidos",
    component: () => import("@/views/area-cliente/pedidos/index.vue"),
    meta: {
      title: 'Meus Pedidos',
      authRequired: true,
      roles: [Roles.LerMeusPedidos]
    },
  },
  {
    path: "/meus-pedidos/ver/:id",
    name: "meus-pedidos-ver",
    component: () => import("@/views/area-cliente/pedidos/form.vue"),
    meta: {
      title: 'Ver Pedido',
      authRequired: true,
      roles: [Roles.LerMeusPedidos]
    },
  },
];