<template>
  <router-view></router-view>
  <ModalWait />
  <ModalNetworkError />
  <Overlay></Overlay>
</template>

<style></style>

<script>
import Overlay from "@/components/loadings/overlay.vue";
import ModalWait from "@/components/modais/modal-wait.vue";
import ModalNetworkError from "@/components/modais/modal-error.vue";
export default {
  name: 'App',
  components: {
    Overlay,
    ModalWait,
    ModalNetworkError
  }
};
</script>
