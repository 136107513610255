import { useToast } from 'vue-toastification'
const toast = useToast()

export const state = {
    type: null,
    message: null,
    modalWait: false,
    modalWaitText: '',

    overlayVisible: false,
    overlayText: '',

    modalError: false,
    modalErrorType: ''
};

export const mutations = {
    success(state, message) {
        state.type = 'alert-success';
        state.message = message;
    },
    error(state, message) {
        state.type = 'alert-danger';
        state.message = message;
    },
    clear(state) {
        state.type = null;
        state.message = null;
    },
    toggleModalWait(state, text = '') {
        state.modalWait = !state.modalWait;
        state.modalWaitText = text
    },
    openModalError(state, type) {
        if (state.modalError === false) {
            state.modalError = true;
            state.modalErrorType = type   
        }
    },
    toggleOverlay(state, text = '') {
        state.overlayVisible = !state.overlayVisible;
        state.overlayText = text
    },
    toggleOverlayUpdateText(state, text = '') {
        state.overlayText = text
    },
    closeModalError(state) {
        state.modalError = false;
    }
};

export const getters = {
    modalWaitVisible(state) {
        return state.modalWait
    },
    modalWaitText(state) {
        return state.modalWaitText
    },
    overlayVisible(state) {
        return state.overlayVisible
    },
    overlayText(state) {
        return state.overlayText
    },

    modalErrorVisible(state) {
        return state.modalError
    },
    modalErrorType(state) {
        return state.modalErrorType
    }
}

const getDefaultToastOptions = (options, timeoutInSeconds) => {
    return {
        position: "top-center",
        timeout: timeoutInSeconds * 1000,
        closeOnClick: false,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: true,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
        isWs: false,
        ...options
    }
};

export const actions = {
    success({ commit }, message) {
        commit('success', message);
    },
    error({ commit }, message) {
        commit('error', message);
    },
    clear({ commit }) {
        commit('clear');
    },
    toggleModalWait({ commit }, text = '') {
        commit('toggleModalWait', text);
    },
    toggleOverlay({ commit }, text = '') {
        commit('toggleOverlay', text);
    },
    toggleOverlayUpdateText({ commit }, text = '') {
        commit('toggleOverlayUpdateText', text);
    },
    openModalError({ commit }, type) {
        commit('openModalError', type);
    },
    closeModalError({ commit }) {
        commit('closeModalError');
    },
    notifyException(_ , { err, timeInSeconds = 4, options = {} }) {
        let msg = err
        if (err && err.response && err.response.data && err.response.data.message) {
            msg = err.response.data.message
        } else if (err && err.response && err.response.message) {
            msg = err.response.message
        } else if (err && err.message) {
            
            if (err.message.toLowerCase() == 'network error') {
                msg = `${err.message}${(err.config && err.config.url) ? ` ${err.config.url}` : ''}`
            } else {
                msg = err.message
            }
        }

        toast.error(msg, getDefaultToastOptions(options, timeInSeconds));
    },
    notifyError(_ ,{ message, timeInSeconds = 5, options = {} }) {
        toast.error(message, getDefaultToastOptions(options, timeInSeconds));
    },
    notifySuccess(_ , { message, timeInSeconds = 5, options = {} }) {
        toast.success(message, getDefaultToastOptions(options, timeInSeconds));
    },
    notifyInfo(_ ,{ message, timeInSeconds = 5, options = {} }) {
        toast.info(message, getDefaultToastOptions(options, timeInSeconds));
    },
    notifyWarning(_ ,{ message, timeInSeconds = 5, options = {} }) {
        toast.warning(message, getDefaultToastOptions(options, timeInSeconds));
    }
};
