import Roles from "@/utils/roles"
import { formMode } from "@/utils/form.helpers";

export default [
  {
    path: "/clientes",
    name: "clientes",
    component: () => import("@/views/area-mention/clientes/index.vue"),
    meta: {
      title: 'Clientes',
      authRequired: true,
      roles: [Roles.LerTodosClientes, Roles.LerMeusClientes]
    },
  },
  {
    path: "/clientes/cadastrar",
    name: "clientes-cadastrar",
    component: () => import("@/views/area-mention/clientes/cadastrar.vue"),
    meta: {
      title: 'Clientes',
      authRequired: true,
      formMode: formMode.Create,
      roles: [ Roles.AdicionarClientes ]
    },
  },
  {
    path: "/clientes/editar/:id",
    name: "clientes-editar",
    component: () => import("@/views/area-mention/clientes/form.vue"),
    meta: {
      title: 'Clientes',
      authRequired: true,
      formMode: formMode.Edit,
      roles: [ Roles.EditarMeusClientes, Roles.EditarTodosClientes ]
    },
    props: true
  },
];