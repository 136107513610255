<template>
    <div>
        <BModal id="modal-1" v-model="visible" size="lg" hideFooter hideHeader noCloseOnBackdrop noCloseOnEsc centered >
          <div v-if="errorType === 'ERR_NETWORK'">
            <BCard no-body class="mb-0 border-0 shadow-none mb-0">
              <BCardBody class="p-sm-5 m-lg-4">
                <div class="mt-2 text-center">
                    <div class="error-img text-center px-5">
                        <img src="@/assets/images/auth/offline-page.gif" class="img-fluid" alt="">
                    </div>
                    <h3 class="mt-2 fw-semibold">Não foi possível completar sua requisição</h3>
                    <p class="text-muted mb-4">Verifique sua conexão com a internet ou tente novamente em instantes. Caso o problema persista, entre em contato com o time Mention para obter suporte.</p>
                    <BButton variant="primary" @click="reload()"><i class="ri-refresh-line align-bottom"></i> Recarregar</BButton>
                </div>
              </BCardBody>
            </BCard>
          </div>
          <div v-if="errorType === 'NOT_AUTHORIZED'">
            <BCard no-body class="mb-0 border-0 shadow-none mb-0">
              <BCardBody class="p-sm-5 m-lg-4">
                <div class="mt-2 text-center">
                    <div class="error-img text-center px-5">
                        <img src="@/assets/images/auth/permission.png" class="img-fluid" alt="">
                    </div>
                    <h3 class="mt-2 fw-semibold">Você não permissão de acesso a função solicitada</h3>
                    <p class="text-muted mb-4">Caso acredite que isso seja um erro, entre em contato com o time Mention para obter suporte.</p>
                    <BButton variant="primary" @click="voltar()"><i class="ri-refresh-line align-bottom"></i> Voltar</BButton>
                    <BButton variant="secondary" @click="goToLogout()" class="ms-2"><i class="bi bi-box-arrow-right align-bottom"></i> Sair do sistema</BButton>
                </div>
              </BCardBody>
            </BCard>
          </div>
          <div v-if="errorType === 'NOT_FOUND'">
            <BCard no-body class="mb-0 border-0 shadow-none mb-0">
              <BCardBody class="p-sm-5 m-lg-4">
                <div class="mt-2 text-center">
                    <div class="error-img text-center px-5">
                        <img src="@/assets/images/auth/404.png" class="img-fluid" alt="">
                    </div>
                    <h3 class="mt-2 fw-semibold">O registro solicitado não foi encontrado</h3>
                    <p class="text-muted mb-4">O registro pode ter sido excluído recentemente, caso acredite que isso seja um erro, entre em contato com o time Mention para obter suporte.</p>
                    <BButton variant="primary" @click="backToHome()"><i class="fas fa-home align-bottom"></i> Voltar para home</BButton>
                </div>
              </BCardBody>
            </BCard>
          </div>


          <div v-if="errorType === 'PASSWORD_CHANGED'">
            <BCard no-body class="mb-0 border-0 shadow-none mb-0">
              <BCardBody class="p-sm-5 m-lg-4">
                <div class="mt-2 text-center">
                    <div class="error-img text-center px-5">
                        <img src="@/assets/images/auth/permission.png" class="img-fluid" alt="">
                    </div>
                    <h3 class="mt-2 fw-semibold">A sua senha foi mudada em outra sessão. Você foi desconectado e precisa fazer login novamente.</h3>
                    <p class="text-muted mb-4">Caso não tenha sido você que alterou a senha, entre em contato com o time Mention para obter suporte.</p>
                    <BButton variant="secondary" @click="goToLogin()" class="ms-2"><i class="bi bi-box-arrow-right align-bottom"></i> Ir para o login</BButton>
                </div>
              </BCardBody>
            </BCard>
          </div>
          
          <div v-if="errorType === 'USER_CHANGED'">
            <BCard no-body class="mb-0 border-0 shadow-none mb-0">
              <BCardBody class="p-sm-5 m-lg-4">
                <div class="mt-2 text-center">
                    <div class="error-img text-center px-5">
                        <img src="@/assets/images/auth/permission.png" class="img-fluid" alt="">
                    </div>
                    <h3 class="mt-2 fw-semibold">O cadastro do seu usuário foi alterado e você precisa acessar novamente a sua conta para aplicar as alterações na sessão atual.</h3>
                    <BButton variant="secondary" @click="goToLogin()" class="ms-2"><i class="bi bi-box-arrow-right align-bottom"></i> Ir para o login</BButton>
                </div>
              </BCardBody>
            </BCard>
          </div>
          
        </BModal>
    </div>
</template>
  
<script>
  import store from "@/state/store";
  import { closeModalError } from "@/state/helpers"
  export default {
    name: 'ModalError',
    components: {
    },
    computed: {
      visible() {
        return store.getters['notification/modalErrorVisible']
      },
      errorType() {
        return store.getters['notification/modalErrorType']
      }
    },
    methods: {
      reload() {
        window.location.reload();
      },
      voltar() {
        closeModalError()
        this.$router.back();
      },
      backToHome() {
        closeModalError()
        this.$router.push({name: 'home'})
      },
      goToLogout() {
        closeModalError()
        this.$router.push({name: 'logout'})
      },
      goToLogin() {
        closeModalError()
        this.$router.push({name: 'login'})
      }
    }
  };
</script>
  