import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from "./state/store";
import BootstrapVueNext from 'bootstrap-vue-next';
import addMomentFilters from './utils/moment.filters';
import setupAppComponents from './utils/globalComponents';
import addAuthorizeDirective, { isInRoles } from './utils/authorize.directive'
import addGlobalLabelsProps from './utils/labels.helpers'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
const defaultToastOptions = {
    transition: "Vue-Toastification__bounce",
    maxToasts: 10,
    newestOnTop: true,
    filterToasts: toasts => {
        // Keep track of existing types or duplicated messages
        const types = {};
        const msgs = {};
        return toasts.reduce((aggToasts, toast) => {
            if (toast.isWs === true) {
                if (toast.content && toast.content.props && toast.content.props.innerHTML) {
                    if (!msgs[toast.content.props.innerHTML]) {
                        aggToasts.push(toast);
                        msgs[toast.content.props.innerHTML] = true;
                    }
                } else {
                    aggToasts.push(toast);
                }
                
            } else {
                // Check if type was not seen before
                if (!types[toast.type]) {
                    aggToasts.push(toast);
                    types[toast.type] = true;
                }
            }
            
            return aggToasts;
        }, []);
    }
}

import i18n from './utils/i18n';

import { vMaska } from "maska";

import './assets/scss/app.scss';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';

import { useWebsocket } from './services/websocket';
import apiClient from './services/api-base';
var ws = useWebsocket();
ws.tryStart();

const app = createApp(App);

addMomentFilters(app);
addAuthorizeDirective(app);
addGlobalLabelsProps(app);
setupAppComponents(app)

app.use(router)
    .use(store)
    .use(BootstrapVueNext)
    .use(i18n)
    .use(ws)
    .use(Toast, defaultToastOptions)
    .directive("maska", vMaska)
    .mount('#app');

//checar se está conectado: nesse caso validar se tem que alterar dados ou se tem que aceitar nova versão dos termos
import logout from '@/utils/logout';
import Roles from './utils/roles';
var tk = localStorage.getItem('mention.token')
if (tk && tk != '' && !isInRoles(Roles.FillClientData) && !isInRoles(Roles.AcceptTerms) && !isInRoles(Roles.ResponderPesquisa)) {
    apiClient.get('me/check-revisao-termo', {}).then(({data}) => {
        if (data.aceitarTermo || data.atualizarDados || data.responderPesquisa) {
            logout()
            router.push({name: 'login'})
        } 
    })
}

import { enviarLogs } from './services/logs-service';
// Enviar logs a cada 5 minutos
setInterval(enviarLogs, 5 * 60 * 1000);
