const addGlobalLabelsProps = (app) => {
    app.config.globalProperties.$labels = {
        getStatusLabel(value) {
            if (value) {
                switch(value.toUpperCase()) {
                    case 'A':
                        return 'Ativo'
                    case 'I':
                        return 'Inativo'
                }
            }
            return value;
        },
    }
}

const selectAtivoInativo = [
    { value: 'A', text: 'Ativo' },
    { value: 'I', text: 'Inativo' },
]

export default addGlobalLabelsProps
export {
    selectAtivoInativo
}
