<template>
    <div class="overlay" v-if="visible">
      <div class="overlay-content">
        <span class="txt pb-3">{{ text }}</span>
        <EllipsisSpinner bgColorSpinner="#fff"></EllipsisSpinner>
      </div>
    </div>
  </template>
  
  <script>
  import store from "@/state/store";
  import EllipsisSpinner from "@/components/loadings/ellipsis-spinner.vue";
  export default {
    name: 'Overlay',
    components: {
      EllipsisSpinner
    },
    computed: {
      visible() {
        return store.getters['notification/overlayVisible']
      },
      text() {
        return store.getters['notification/overlayText']
      }
    }
  };
</script>

  <style scoped scss>
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
  }
  .overlay-content {
    padding: 20px;
    border-radius: 5px;
    position: relative;
    text-align: center;
  }
  .txt {
    color: #fff !important;
    font-weight: bolder;
    display: block;
    font-size: large;
  }
  </style>