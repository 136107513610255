import store from "@/state/store";
import axios from "axios";

// Função para abrir o banco de dados IndexedDB
const openDatabase = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open("logsDatabase", 1);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      db.createObjectStore("logs", { autoIncrement: true });
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

// Função para armazenar logs no IndexedDB
export const armazenarLog = async (log) => {
  const db = await openDatabase();
  const transaction = db.transaction("logs", "readwrite");
  const dbStore = transaction.objectStore("logs");
  dbStore.add(log);
};

// Função para enviar logs para o servidor de tempos em tempos
export const enviarLogs = async () => {
  const db = await openDatabase();
  const transaction = db.transaction("logs", "readonly");
  const dbStore = transaction.objectStore("logs");
  const logs = [];

  dbStore.openCursor().onsuccess = (event) => {
    const cursor = event.target.result;
    if (cursor) {
      logs.push(cursor.value);
      cursor.continue();
    } else {
      if (logs.length > 0) {
        axios
          .post(process.env.VUE_APP_APIURL + "/client-logs", {
            logsJson: JSON.stringify(logs),
            username: store.getters["account/userEmail"]
          })
          .then((response) => {
            console.log("Logs enviados com sucesso:", response.data);
            // Limpar logs após envio bem-sucedido
            const deleteTransaction = db.transaction("logs", "readwrite");
            const deleteStore = deleteTransaction.objectStore("logs");
            deleteStore.clear();
          })
          .catch((error) => {
            console.error("Erro ao enviar os logs:", error);
          });
      }
    }
  };
};
