import Roles from "@/utils/roles"

export default [
    {
        path: "/termos-uso",
        name: "termos-uso",
        component: () => import("@/views/area-mention/termos-uso/index.vue"),
        meta: {
          title: 'Termos de Uso',
          authRequired: true,
          roles: [ Roles.TermosUso ]
        },
    },
    {
      path: "/termos-uso/editar/:id?",
      name: "termos-uso-editar",
      component: () => import("@/views/area-mention/termos-uso/form.vue"),
      meta: {
        title: 'Termos de Uso',
        authRequired: true,
        roles: [ Roles.TermosUsoGerenciar ]
      },
  },
    //
];