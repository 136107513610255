import Roles from "@/utils/roles"

export default [
  {
    path: "/minhas-sugestoes",
    name: "minhas-sugestoes-conteudo",
    component: () => import("@/views/area-cliente/sugestoes-conteudo/index.vue"),
    meta: {
      title: 'Sugestões de Conteúdo',
      authRequired: true,
      roles: [Roles.LerMinhasSugestoes]
    },
  },
  {
    path: "/minhas-sugestoes/ver/:id",
    name: "minha-sugestoes-view",
    component: () => import("@/views/area-cliente/sugestoes-conteudo/form.vue"),
    meta: {
      title: 'Sugestões de Conteúdo',
      authRequired: true,
      roles: [Roles.LerMinhasSugestoes]
    },
  },
];