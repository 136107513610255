import Roles from "@/utils/roles"

export default [
  {
    path: "/sugestoes-conteudo",
    name: "sugestoes-conteudo",
    component: () => import("@/views/area-mention/sugestoes-conteudo/index.vue"),
    meta: {
      title: 'Sugestões de Conteúdo',
      authRequired: true,
      roles: [Roles.LerTodosSugestoesConteudo, Roles.LerClientesSugestoesConteudo]
    }
  },
  {
    path: "/sugestoes-conteudo/selecionar-produto",
    name: "sugestoes-conteudo-selecionar-produto",
    component: () => import("@/views/area-mention/sugestoes-conteudo/select-produto.vue"),
    meta: {
      title: 'Sugestões de Conteúdo',
      authRequired: true,
      roles: [Roles.CriarTodosSugestoesConteudo, Roles.CriarClientesSugestoesConteudo]
    }
  },
  {
    path: "/sugestoes-conteudo/criar/:produtoId/:clienteId",
    name: "sugestoes-conteudo-create",
    component: () => import("@/views/area-mention/sugestoes-conteudo/form.vue"),
    meta: {
      title: 'Sugestões de Conteúdo',
      authRequired: true,
      roles: [Roles.CriarTodosSugestoesConteudo, Roles.CriarClientesSugestoesConteudo]
    }
  },
  {
    path: "/sugestoes-conteudo/editar/:id",
    name: "sugestoes-conteudo-edit",
    component: () => import("@/views/area-mention/sugestoes-conteudo/form.vue"),
    meta: {
      title: 'Sugestões de Conteúdo',
      authRequired: true,
      roles: [Roles.LerTodosSugestoesConteudo, Roles.LerClientesSugestoesConteudo]
    }
  }
];