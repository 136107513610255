import apiClient from '@/services/api-base'
import { useWebsocket } from '@/services/websocket';


export const state = {
  tipoUsuario: localStorage.getItem('mention.tipousuario'),
  username: localStorage.getItem('mention.username'),
  useremail: localStorage.getItem('mention.useremail'),
  userId: localStorage.getItem('mention.userid'),
  token: localStorage.getItem('mention.token'),
  profilePicture: localStorage.getItem('mention.profilePicture'),
  cliente: localStorage.getItem('mention.cliente')
};

export const mutations = {
  CHANGE_TOKEN(state, token) {
    state.token = token;
    saveState('mention.token', token);
  },
  CHANGE_USERNAME(state, username) {
    state.username = username;
    saveState('mention.username', username);
  },
  CHANGE_EMAIL(state, useremail) {
    state.useremail = useremail;
    saveState('mention.useremail', useremail);
  },
  CHANGE_USERID(state, userId) {
    state.userId = userId;
    saveState('mention.userid', userId);
  },
  CHANGE_TIPOUSUARIO(state, tipoUsuario) {
    state.tipoUsuario = tipoUsuario;
    saveState('mention.tipousuario', tipoUsuario);
  },
  CHANGE_PROFILEPICTURE(state, profilePicture) {
    state.profilePicture = profilePicture;
    saveState('mention.profilePicture', profilePicture);
  },
  CHANGE_CLIENTE(state, cliente) {
    state.cliente = cliente;
    saveState('mention.cliente', cliente);
  },
};


export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
      return !!state.token
  },
  tipoUsuario(state) {
    return state.tipoUsuario
  },
  userName(state) {
    return state.username
  },
  userEmail(state) {
    return state.useremail
  },
  token(state) {
    return state.token ?? ''
  },
  roles() {
    if ((state.token ?? '') != '') {
      return JSON.parse(window.atob(localStorage.getItem("mention.token").split(".")[1])).role
    }
    return []
  },
  isMention(state) {
    return state.tipoUsuario === 'mention'
  },
  isCliente(state) {
    return state.tipoUsuario === 'cliente'
  },
  profilePicture(state) {
    return state.profilePicture ?? null
  },
}


export const actions = {
    // Logs in the current user.
    logIn({ commit,  getters }, { email, password } = {}) {
      if (getters.loggedIn)  {
        commit('CHANGE_TOKEN', null)
        commit('CHANGE_USERNAME', null)
        commit('CHANGE_USERID', null)
        commit('CHANGE_EMAIL', null)
        commit('CHANGE_TIPOUSUARIO', null)
        commit('CHANGE_PROFILEPICTURE', null)
        commit('CHANGE_CLIENTE', null)
        //todo - refactory
        saveState('mention.countApresentacao', null)
        saveState('mention.fromLogin', null)
      }

      return new Promise((resolve, reject) => {
        apiClient.post('account/efetuar-login', { email, password }, false).then((response) => {
            commit('CHANGE_TOKEN', response.data.token)
            commit('CHANGE_USERNAME',  response.data.userName)
            commit('CHANGE_USERID',  response.data.userId)
            commit('CHANGE_EMAIL',  response.data.userEmail)
            commit('CHANGE_PROFILEPICTURE',  response.data.foto)
            commit('CHANGE_TIPOUSUARIO',  response.data.tipoUsuario)
            commit('CHANGE_CLIENTE', response.data.cliente)
            //todo - refactory
            //remover pode causar issues na hora de fazer a conexao ao ws
            saveState('mention.token', response.data.token);
            saveState('mention.countApresentacao', response.data.countApresentacao)
            saveState('mention.fromLogin', '1')
            const ws = useWebsocket()
            ws.tryStart();
            resolve(true);
        }).catch(err => {
          reject(err);
        })
      });
  },
  updateToken({ commit}, token){
    commit('CHANGE_TOKEN', token)
  },
  updateUserAvatar({ commit }, avavar) {
    commit('CHANGE_PROFILEPICTURE', avavar)
  },
  updateUserNome({ commit }, nome) {
    commit('CHANGE_USERNAME', nome)
  }
}


// ===
// Private helpers
// ===
function saveState(key, value) {
  if (!value) {
    localStorage.removeItem(key)
  } else {
    localStorage.setItem(key, value)
  }
}