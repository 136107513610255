import { ref } from "vue"

const db = ref(null);

const clearFileDB = () => {
    window.indexedDB.deleteDatabase('FileUploadDB');
}

const deleteFileFromDB = (fileId) => {
    return new Promise((resolve, reject) => {
      const transaction = db.value.transaction(['files'], 'readwrite');
      const objectStore = transaction.objectStore('files');
      const request = objectStore.delete(fileId);
  
      request.onsuccess = function() {
        console.log('File deleted from IndexedDB:', fileId);
        resolve();
      };
  
      request.onerror = function(event) {
        console.log('Error deleting file from IndexedDB:', event.target.error);
        reject(event.target.error);
      };
    });
}

const addFileToDB = (fileStr, fileType, name) => {
    return new Promise((resolve, reject) => {
      const transaction = db.value.transaction(['files'], 'readwrite');
      const objectStore = transaction.objectStore('files');
      const request = objectStore.add({ name: name, type: fileType, data: fileStr });
  
      request.onsuccess = function(event) {
        console.log('File added to IndexedDB:', event.target.result);
        resolve(event.target.result);
      };
  
      request.onerror = function(event) {
        console.log('Error adding file to IndexedDB:', event.target.error);
        reject(event.target.error);
      };
    });
}
  
const loadFileFromDB = (fileId, actionDone) => {
    const transaction = db.value.transaction(['files'], 'readonly');
      const objectStore = transaction.objectStore('files');
      const request = objectStore.get(fileId);
  
    request.onsuccess = function(event) {
      const fileData = event.target.result;
      if (fileData) {
        console.log('File retrieved from IndexedDB:', fileData);
        actionDone(fileData.data)
      } else {
        console.log('File not found in IndexedDB.');
      }
    };
  
    request.onerror = function(event) {
      console.log('Error retrieving file from IndexedDB:', event.target.error);
    };
}

const loadFileFromDBAsync = async (fileId) => {
    return new Promise((resolve, reject) => {
        const transaction = db.value.transaction(['files'], 'readonly');
        const objectStore = transaction.objectStore('files');
        const request = objectStore.get(fileId);
    
        request.onsuccess = function(event) {
            const fileData = event.target.result;
            if (fileData) {
              console.log('File retrieved from IndexedDB:', fileData);
              resolve(fileData.data)
            } else {
              console.log('File not found in IndexedDB.');
            }
        };
        request.onerror = function(event) {
          console.log('Error get file to IndexedDB:', event.target.error);
          reject(event.target.error);
        };
    });
}

const initDB = (done = () => {}) => {
    return new Promise((resolve, reject) => {
        const request = window.indexedDB.open('FileUploadDB', 1);

        request.onerror = function(event) {
            console.log('IndexedDB error:', event.target.error);
            reject(event.target.error);
        };

        request.onupgradeneeded = function(event) {
            db.value = event.target.result;
            db.value.createObjectStore('files', { keyPath: 'id', autoIncrement: true });
            console.log('IndexedDB created.');
        };

        request.onsuccess = function(event) {
            db.value = event.target.result;
            done();
            console.log('IndexedDB opened successfully.');
            resolve(db);
        };
    });
}

const dataURLToBlob = (dataURL) => {
    const parts = dataURL.split(',');
    const byteString = atob(parts[1]);
    const mimeString = parts[0].split(':')[1].split(';')[0];

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
}

export {
    initDB,
    deleteFileFromDB,
    addFileToDB,
    loadFileFromDB,
    loadFileFromDBAsync,
    dataURLToBlob,
    clearFileDB
}