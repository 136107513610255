import { formMode } from "@/utils/form.helpers";
import Roles from "@/utils/roles"

export default [
  {
    path: "/meus-usuarios",
    name: "meus-usuarios",
    component: () => import("@/views/area-cliente/usuarios/index.vue"),
    meta: {
      title: 'Usuários',
      authRequired: true,
      roles: [Roles.LerMeusUsuarios]
    },
  },
  {
    path: "/meus-usuarios/criar",
    name: "meus-usuarios-criar",
    component: () => import("@/views/area-cliente/usuarios/form.vue"),
    meta: {
      title: 'Usuários',
      authRequired: true,
      formMode: formMode.Create,
      roles: [Roles.AdicionarMeusUsuarios]
    },
  },
  {
    path: "/meus-usuarios/ver/:id",
    name: "meus-usuarios-editar",
    component: () => import("@/views/area-cliente/usuarios/form.vue"),
    meta: {
      title: 'Usuários',
      formMode: formMode.Edit,
      authRequired: true,
      roles: [Roles.LerMeusUsuarios]
    },
  },
];