import Roles from "@/utils/roles"

export default [

  {
    path: "/fazer-pedido",
    name: "fazer-pedido",
    component: () => import("@/views/area-mention/fazer-pedido/select-produto.vue"),
    meta: {
      title: 'Fazer Pedido',
      authRequired: true,
      roles: [Roles.FazerPedidoClientes, Roles.FazerPedidoTodos]
    },
  },
  {
    path: "/fazer-pedido/criar/:produtoId/:clienteId",
    name: "fazer-pedido-create",
    component: () => import("@/views/area-mention/fazer-pedido/form.vue"),
    meta: {
      title: 'Fazer Pedido',
      authRequired: true,
      roles: [Roles.FazerPedidoClientes, Roles.FazerPedidoTodos]
    },
  },

  {
      path: "/pedidos",
      name: "pedidos",
      component: () => import("@/views/area-mention/pedidos/index.vue"),
      meta: {
        title: 'Pedidos',
        authRequired: true,
        roles: [Roles.LerTodosPedidos, Roles.LerClientesPedidos]
      },
  },
  {
      path: "/pedidos/:id",
      name: "pedidos-view",
      component: () => import("@/views/area-mention/pedidos/form.vue"),
      meta: {
        title: 'Pedido',
        authRequired: true,
        roles: [Roles.LerTodosPedidos, Roles.LerClientesPedidos]
      },
  },
];