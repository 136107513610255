import { notifyException,/*, notifySuccess*/ 
notifySuccess} from "@/state/helpers";

const formMode = {
    View: "VIEW",
    Edit: "EDIT",
    Create: "CREATE"
}
//mapFielsEntityToView deve ser um array com objetos {entity: '', form: ''} onde
//podem ser mapeados campos das entidades com nomes diferentes no formulario
const checkApiError = (err, $externalResults, mapFielsEntityToView = []) => {
    if (err && err.response && err.response.status && err.response.status == 404) {
        //not found
        return;
    }
    if (err && err.response && err.response.data && err.response.data.modelErrors){
        var errors = {};

        err.response.data.modelErrors.forEach(errorItem => {
            let field = errorItem.field;
            if (mapFielsEntityToView.length > 0 && mapFielsEntityToView.filter(m => m.entity == errorItem.field).length > 0) {
                field = mapFielsEntityToView.filter(m => m.entity == errorItem.field)[0].form
            }
            errors[field] = errorItem.errors
        });
        //console.log(err.response.data.modelErrors)
        if ($externalResults) {
            $externalResults.value = errors
        }

    } else {
        notifyException(err)
    }
}

const checkErrorNotify = (err) => {
    if (err && err.response && err.response.data && err.response.data.modelErrors){
        var errorsResult = '';
        err.response.data.modelErrors.forEach(errorItem => {
            errorsResult += errorItem.errors.join(",") + "\r\n";
        });
        notifyException(errorsResult)
    } else {
        notifyException(err)
    }
}

const emitSuccessMessageAndRedirect = (msg, router, route) => {
    router.push(route)
    notifySuccess(msg, 5)
}

const isEditando = (currentRoute) => {
    return currentRoute && currentRoute.meta && currentRoute.meta.formMode == formMode.Edit
}

const isCriando = (currentRoute) => {
    return currentRoute && currentRoute.meta && currentRoute.meta.formMode == formMode.Create
}

const isVisualizando = (currentRoute) => {
    return currentRoute && currentRoute.meta && currentRoute.meta.formMode == formMode.View
}

const getPathTitle = (currentRoute) => {
    if (isEditando(currentRoute)) {
        return 'Editar'
    }
    if (isCriando(currentRoute)) {
        return 'Criar'
    }
    if (isVisualizando(currentRoute)) {
        return 'Visualizar'
    }
    return ''
}

const waitForElement = (querySelector, timeout=0) => {
    const startTime = new Date().getTime();
    return new Promise((resolve, reject)=>{
        const timer = setInterval(()=>{
            const now = new Date().getTime();
            if(document.querySelector(querySelector)){
                clearInterval(timer);
                resolve(document.querySelector(querySelector));
            } else if(timeout && now - startTime >= timeout){
                clearInterval(timer);
                reject();
            }
        }, 100);
    });
}


export {
    checkApiError,
    checkErrorNotify,
    isEditando,
    isCriando,
    isVisualizando,
    formMode,
    getPathTitle,
    emitSuccessMessageAndRedirect,
    waitForElement
}