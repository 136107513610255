import Roles from "@/utils/roles"
import { formMode } from "@/utils/form.helpers";

export default [
    {
        path: "/usuarios",
        name: "usuarios",
        component: () => import("@/views/area-mention/usuarios/index.vue"),
        meta: {
          title: 'Usuários',
          authRequired: true,
          roles: [Roles.LerUsuarios]
        },
      },
      {
        path: "/usuarios/:id",
        name: "usuarios-editar",
        component: () => import("@/views/area-mention/usuarios/form.vue"),
        meta: {
          title: 'Usuários',
          authRequired: true,
          formMode: formMode.Edit,
          roles: [Roles.EditarUsuarios]
        },
      },
      {
        path: "/usuarios/cadastrar/:tipo",
        name: "usuarios-cadastrar",
        component: () => import("@/views/area-mention/usuarios/form.vue"),
        meta: {
          title: 'Usuários',
          authRequired: true,
          formMode: formMode.Create,
          roles: [Roles.CriarUsuarios]
        },
      },
];