<template>
    <div>
        <BRow v-if="!props.loaded" class="gy-2 mt-4 mb-4 text-center">
            <BCol md="12">
                <EllipsisSpinner></EllipsisSpinner>
            </BCol>
        </BRow>
        <div v-else>
            <slot></slot>
        </div>
    </div>
    
</template>

<style scoped scss>

</style>

<script setup>
    import {defineProps} from "vue"
    import EllipsisSpinner from "./ellipsis-spinner.vue";
    const props = defineProps({
        loaded: { type: Boolean, required: true }
    })
</script>