import Roles from "@/utils/roles"

export default [
    {
        path: "/relatorio-publicacoes",
        name: "relatorio-publicacoes",
        component: () => import("@/views/area-mention/relatorio-publicacoes/index.vue"),
        meta: {
          title: 'Relatório de Publicações',
          authRequired: true,
          roles: [Roles.RelatorioClientesPublicacoes, Roles.RelatorioTodosPublicacoes]
        },
    },
    {
        path: "/relatorio-pedidos",
        name: "relatorio-pedidos",
        component: () => import("@/views/area-mention/relatorio-pedidos/index.vue"),
        meta: {
          title: 'Relatório de Pedidos',
          authRequired: true,
          roles: [Roles.RelatorioClientesPedidos, Roles.RelatorioTodosPedidos]
        },
    },
    {
        path: "/relatorio-engajamento",
        name: "relatorio-engajamento",
        component: () => import("@/views/area-mention/relatorio-engajamento/index.vue"),
        meta: {
          title: 'Relatório de Engajamento',
          authRequired: true,
          roles: [Roles.RelatorioEngajamento]
        },
    },
];